// This module provides some wrappers to the Firestore data store, which abstract
// it but don't provide any business logic. One reason to do this is to provide a
// mock seam for unit testing.

import {
  collection,
  deleteField,
  doc,
  DocumentSnapshot,
  Firestore,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import {
  Concept,
  ConceptSchema,
  Illustration,
  IllustrationSchema,
  User,
  VocabularyEntry,
  VocabularyEntrySchema,
} from "./schema";

// Give access to the firebase data store.
export interface DataStoreContext {
  firebaseDB: Firestore;
}

export function getIllustrationURL(illustration: Illustration) {
  return `https://static.langsnap.com/${illustration.url}`;
}

export async function getOrCreateUser(
  context: DataStoreContext,
  uid: string
): Promise<DocumentSnapshot> {
  // Set a default user record, merging in case one already exists
  await setDoc(doc(context.firebaseDB, "users", uid), {}, { merge: true });

  const docRef = doc(context.firebaseDB, "users", uid);
  return await getDoc(docRef);
}

export async function setUserFields(
  context: DataStoreContext,
  uid: string,
  userFields: Partial<Omit<User, "id">>
) {
  const docRef = doc(context.firebaseDB, "users", uid);
  await setDoc(docRef, userFields, { merge: true });
}

export async function setVocabularyEntryFields(
  context: DataStoreContext,
  uid: string,
  conceptId: string,
  update: Partial<VocabularyEntry>
) {
  const docRef = doc(context.firebaseDB, `users/${uid}/vocabulary`, conceptId);

  // We automatically clear the lastSeen field at this point, which is
  // deprecated.
  await setDoc(docRef, { ...update, lastSeen: deleteField() }, { merge: true });
}

export async function getConcepts(
  context: DataStoreContext
): Promise<Concept[]> {
  const snapshot = await getDocs(collection(context.firebaseDB, "concepts"));

  return snapshot.docs.map((doc) =>
    ConceptSchema.parse({ ...doc.data(), id: doc.id })
  );
}

export async function getVocabulary(
  context: DataStoreContext,
  uid: string
): Promise<VocabularyEntry[]> {
  const snapshot = await getDocs(
    collection(context.firebaseDB, `users/${uid}/vocabulary`)
  );

  return snapshot.docs.map((doc) =>
    VocabularyEntrySchema.parse({ ...doc.data(), conceptId: doc.id })
  );
}

export async function getIllustrations(
  context: DataStoreContext
): Promise<Illustration[]> {
  const snapshot = await getDocs(
    collection(context.firebaseDB, "illustrations")
  );

  return snapshot.docs.map((doc) =>
    IllustrationSchema.parse({ ...doc.data(), id: doc.id })
  );
}
