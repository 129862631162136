// Logic for testing understanding of a concept via a choice between
// multiple images

import assertNever from "assert-never";
import seedrandom from "seedrandom";
import { Concept, Illustration } from "../schema";
import { UserState } from "../interfaces";
import { randomInt, ShuffledStream } from "../utils/shuffle";

export interface MultipleChoiceTestSpec {
  testType: "multipleChoice";
}

export interface MultipleChoiceTestState extends MultipleChoiceTestSpec {
  illustrations: Illustration[];
  correctAnswer: number;
}

// Draw a decoy image, i.e. an image of a concept that satisfies the concepts
// in the concept filter.
//
// Since the same image can illustrate more than one concept, we need to ensure
// that the image found not only illustrates a different concept, but also doesn't
// inadvertently illustrate one of the concepts ruled out by the concept filter.
function drawDecoyImage(
  userStateStore: UserState,
  excludeConcepts: Concept[],
  rng?: seedrandom.PRNG
) {
  let attempts = 5;

  while (attempts > 0) {
    const decoyConcept = userStateStore.getRandomConcepts(1, {
      excludeConceptIds: excludeConcepts.map((concept) => concept.id),
      minIllustrations: 1,
    })[0];

    const decoyIllustration = userStateStore.getIllustration(
      decoyConcept,
      randomInt(decoyConcept.illustrations.length - 1, rng)
    );

    const overlaps = excludeConcepts.filter((concept) => {
      if (concept.category === "noun") {
        return concept.illustrations.includes(decoyIllustration.id);
      } else if (concept.category === "stative_verb") {
        return (
          concept.illustrations.find(
            (checkIllustration) =>
              checkIllustration.illustration == decoyIllustration.id
          ) !== undefined
        );
      } else {
        assertNever(concept);
      }
    });

    if (overlaps.length === 0) {
      return decoyIllustration;
    }

    attempts--;
  }

  throw new Error("Failed to find an appropriate image");
}

export const drawMultipleChoiceTest = (
  userStateStore: UserState,
  concept: Concept,
  contrastingConcept: Concept,
  illustrationIDStream: ShuffledStream<string>,
  rng?: seedrandom.PRNG
): MultipleChoiceTestState => {
  const correctIllustration = userStateStore.getIllustrationById(
    illustrationIDStream.next()
  );

  const contrastingConceptIllustration = userStateStore.getIllustration(
    contrastingConcept,
    randomInt(contrastingConcept.illustrations.length - 1, rng)
  );

  const decoyIllustrations: Illustration[] = [
    drawDecoyImage(userStateStore, [concept, contrastingConcept], rng),
    drawDecoyImage(userStateStore, [concept, contrastingConcept], rng),
  ];

  const incorrectIllustrations = [
    contrastingConceptIllustration,
    decoyIllustrations[0],
    decoyIllustrations[1],
  ];

  const baseLength = incorrectIllustrations.length;
  while (incorrectIllustrations.length < 4) {
    incorrectIllustrations.push(
      incorrectIllustrations[randomInt(baseLength - 1, rng)]
    );
  }

  const illustrations = incorrectIllustrations.slice(0, 3);
  const correctIndex = randomInt(3, rng);
  illustrations.splice(correctIndex, 0, correctIllustration);

  return {
    testType: "multipleChoice",
    correctAnswer: correctIndex,
    illustrations,
  };
};
