import { makeObservable, observable, when } from "mobx";
import { UserState } from "../interfaces";
import { ConceptLearningState } from "./VocabularyState";

export interface LoadingStep {
  stepType: "loading";
}

// The next action should be to learn or refresh one or more concepts.
// The UI may choose to interleave the steps of teaching and testing.
export interface TeachConceptStep {
  stepType: "teachConcept";
  conceptId: string;
}

export interface WelcomeStep {
  stepType: "welcome";
}

export interface WelcomeBackStep {
  stepType: "welcomeBack";
}

export type ProgramStep =
  | LoadingStep
  | TeachConceptStep
  | WelcomeStep
  | WelcomeBackStep;

// Holds the state of what's recently been taught, and makes decisions
// about what next to show the user.
export default class LearningProgram {
  private currentStep: ProgramStep;

  private userState: UserState;

  constructor(userState: UserState) {
    this.currentStep = { stepType: "loading" };
    this.userState = userState;

    makeObservable<LearningProgram, "currentStep">(this, {
      currentStep: observable,
    });

    when(
      () => userState.loading === false,
      () => {
        if (userState.welcomePageSeen) {
          this.currentStep = { stepType: "welcomeBack" };
        } else {
          this.currentStep = { stepType: "welcome" };
        }
      }
    );
  }

  public getCurrentStep(): ProgramStep {
    return this.currentStep;
  }

  // Called when the current step has completed, which will trigger
  // making a decision about what the next step should be.
  public completeStep() {
    if (this.userState.unlearnedConcepts.length > 0) {
      this.currentStep = {
        stepType: "teachConcept",
        conceptId: this.userState.unlearnedConcepts[0],
      };
    } else {
      const concepts = this.userState.vocabularyState.filter(
        (vocab) => vocab.concept.audio !== undefined
      );

      concepts.sort((a: ConceptLearningState, b: ConceptLearningState) => {
        const lastResultA = a.results[a.results.length - 1];
        const lastResultB = b.results[b.results.length - 1];

        if (lastResultA === undefined && lastResultB === undefined) {
          return 0;
        } else if (lastResultA === undefined) {
          return -1;
        } else if (lastResultB === undefined) {
          return 1;
        } else {
          return lastResultA.date.valueOf() - lastResultB.date.valueOf();
        }
      });

      this.currentStep = {
        stepType: "teachConcept",
        conceptId: concepts[0].concept.id,
      };
    }
  }
}
