import { useContext } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLoaderData } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getAllConcepts, updateConcept } from "../../AdminDataStore";
import { AdminStoreContext } from "../../AdminDataStoreContext";
import { Concept } from "../../schema";
import { ErrorCard } from "../ErrorCard";
import EditableConceptList from "./EditableConceptList";

export interface LoaderData {
  concept: Concept;
}

// Interface to edit the details of a single concept.
export const ConceptEdit = () => {
  const { concept } = useLoaderData() as LoaderData;

  const adminStoreContext = useContext(AdminStoreContext);

  const conceptsResult = useQuery({
    queryKey: ["concepts", "all"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllConcepts(adminStoreContext);
      }
    },
  });

  if (adminStoreContext === undefined) {
    return <ErrorCard>Not logged in</ErrorCard>;
  }

  const updateContrastingConcepts = (contrastingConcepts: Concept[]) => {
    updateConcept(adminStoreContext, concept.id, {
      contrastingConcepts: contrastingConcepts.map((concept) => concept.id),
    });
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          href="/admin"
          linkAs={Link}
          linkProps={{ to: "/admin" }}
        >
          Admin home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href="/admin/concepts"
          linkAs={Link}
          linkProps={{ to: "/admin/concepts" }}
        >
          Concepts
        </Breadcrumb.Item>

        <Breadcrumb.Item active>{concept.name}</Breadcrumb.Item>
      </Breadcrumb>

      <table>
        <tbody>
          <tr>
            <th>Category</th>
            <td>{concept.category}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{concept.name}</td>
          </tr>
          <tr>
            <th>Translations</th>
            <td>{JSON.stringify(concept.translations)}</td>
          </tr>

          <tr>
            <th>Descriptions</th>
            <td>{JSON.stringify(concept.descriptions)}</td>
          </tr>

          {conceptsResult.data !== undefined && (
            <tr>
              <th>Contrasting concepts</th>
              <td>
                <EditableConceptList
                  concepts={conceptsResult.data}
                  selectedConcepts={conceptsResult.data.filter((otherConcept) =>
                    concept.contrastingConcepts?.includes(otherConcept.id)
                  )}
                  onConceptsUpdated={(selectedConcepts) =>
                    updateContrastingConcepts(selectedConcepts)
                  }
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ConceptEdit;
