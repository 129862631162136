import React, { useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInAnonymously,
  browserLocalPersistence,
  setPersistence,
  signInWithEmailAndPassword,
} from "firebase/auth";

import * as Sentry from "@sentry/react";

import { getFirebase } from "./firebase";

const firebaseApp = getFirebase();

export interface LoggedInAuth {
  loggedIn: true;
  uid: string;
  roles: string[];
}

export interface LoggedOutAuth {
  loggedIn: false;
  error: boolean;
}

export type AuthState = LoggedInAuth | LoggedOutAuth;

export interface AuthActions {
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<void>;
}

export type Auth = AuthState & AuthActions;

export const AuthContext = React.createContext<Auth>({
  loggedIn: false,
  error: false,
  signInWithEmailAndPassword: () => Promise.resolve(),
});

export interface Props {
  children: React.ReactNode;
}

export function WithAuth({ children }: Props) {
  const [authState, setAuthState] = useState<AuthState>({
    loggedIn: false,
    error: false,
  });

  const componentSignIn = async (email: string, password: string) => {
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password);

    const tokenResult = await auth.currentUser?.getIdTokenResult();

    if (auth.currentUser !== null && tokenResult !== undefined) {
      const roles = (tokenResult.claims.roles ?? []) as string[];
      setAuthState({
        loggedIn: true,
        uid: auth.currentUser.uid,
        roles,
      });
    }
  };

  useEffect(() => {
    const auth = getAuth(firebaseApp);

    onAuthStateChanged(auth, async () => {
      if (auth.currentUser !== null) {
        const tokenResult = await auth.currentUser.getIdTokenResult();
        const roles = (tokenResult.claims.roles ?? []) as string[];
        setAuthState({
          loggedIn: true,
          uid: auth.currentUser.uid,
          roles,
        });
      } else {
        signInAnonymously(auth)
          .then((data) => {
            setAuthState({ loggedIn: true, uid: data.user.uid, roles: [] });
          })
          .catch((error) => {
            setAuthState({ loggedIn: false, error: true });
            const errorCode = error.code;
            const errorMessage = error.message;
            Sentry.captureEvent({
              message: "Failed to authenticate user",
              level: "error",
              extra: {
                errorCode,
                errorMessage,
              },
            });
          });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{ ...authState, signInWithEmailAndPassword: componentSignIn }}
    >
      {children}
    </AuthContext.Provider>
  );
}
