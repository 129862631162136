import { useCallback, useContext, useEffect, useState } from "react";
import { UserStateContext } from "../UserStateContext";
import Welcome from "../components/Welcome";
import { observer } from "mobx-react-lite";
import { when } from "mobx";
import { Container } from "react-bootstrap";

import WelcomeBack from "../components/WelcomeBack";
import TeachConcept from "../components/TeachConcept";
import LearningProgram, { ProgramStep } from "../teaching/LearningProgram";
import LoadingCard from "../components/LoadingCard";
import VocabularyStatusCard from "../components/VocabularyStatusCard";
import ErrorPage from "./ErrorPage";

const defaultDelayAfter = 1000;

// The home page is effectively the single-page app that provides all
// end user functionality.
export default observer(function Home() {
  const userStateContext = useContext(UserStateContext);
  const [program, setProgram] = useState<LearningProgram | null>(null);
  const [currentStep, setCurrentStep] = useState<ProgramStep>({
    stepType: "loading",
  });

  // This is incremented when the step changes, and is used as a key
  // to ensure that we fully unmount and remount when starting a new
  // step.
  const [stepCount, setStepCount] = useState(0);

  const resetState = () => {
    const program = new LearningProgram(userStateContext);
    setProgram(program);

    when(
      () => program.getCurrentStep().stepType !== "loading",
      () => {
        setCurrentStep(program.getCurrentStep());
      }
    );
  };

  useEffect(resetState, [userStateContext]);

  const onComplete = useCallback(() => {
    if (program === null) {
      return;
    }

    program.completeStep();
    setTimeout(() => {
      setCurrentStep(program.getCurrentStep());
      setStepCount(stepCount + 1);
    }, defaultDelayAfter);
  }, [program, stepCount]);

  if (userStateContext.error !== undefined) {
    return (
      <div className="App">
        <ErrorPage error={userStateContext.error} reset={resetState} />
      </div>
    );
  }

  if (program === null || currentStep.stepType === "loading") {
    return (
      <div className="App">
        <Container>
          <LoadingCard />
        </Container>
      </div>
    );
  }

  return (
    <div className="App">
      {currentStep.stepType === "welcome" && (
        <Container>
          <Welcome
            onComplete={() => {
              onComplete();
              userStateContext.setWelcomePageSeen();
            }}
            key={stepCount}
          />
        </Container>
      )}

      {currentStep.stepType === "welcomeBack" && (
        <Container>
          <WelcomeBack onComplete={onComplete} key={stepCount} />
        </Container>
      )}

      {currentStep.stepType === "teachConcept" && (
        <>
          <Container>
            <TeachConcept
              conceptId={currentStep.conceptId}
              onComplete={onComplete}
              key={stepCount}
            />
          </Container>
          <div className="persistent-status">
            <VocabularyStatusCard />
          </div>
        </>
      )}
    </div>
  );
});
