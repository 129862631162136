import Select from "react-select";

import { Concept } from "../../schema";

export interface Props {
  // All concepts that are available in the system (or at least, all the ones
  // that should be available to select from for this control)
  concepts: Concept[];

  // List of concepts currently selected by this picker
  selectedConcepts: Concept[];

  onChange?: (newConcepts: Concept[]) => void;
}

function conceptToSelectOption(concept: Concept) {
  return { value: concept.id, label: concept.name };
}

// Multi-select box that allows picking concepts.
export const ConceptSelector = ({
  concepts,
  selectedConcepts,
  onChange,
}: Props) => {
  return (
    <Select
      options={concepts.map(conceptToSelectOption)}
      isMulti={true}
      value={selectedConcepts.map(conceptToSelectOption)}
      onChange={(newValue) => {
        if (onChange !== undefined) {
          onChange(
            newValue.map((selection) =>
              concepts.find((concept) => concept.id === selection.value)
            ) as Concept[]
          );
        }
      }}
    />
  );
};

export default ConceptSelector;
