import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { AuthContext } from "../AuthContext";

export interface Props {
  children: React.ReactNode;
}

export default observer(function AdminRoute({ children }: Props) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.loggedIn && !authContext.roles.includes("admin")) {
      navigate("/");
    }
  }, [authContext, navigate]);

  if (!authContext.loggedIn) {
    return <p>Loading...</p>;
  }

  return <>{children}</>;
});
