import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useDelayedAudio } from "../hooks/audio";

export interface Props {
  onComplete: () => void;
}

export const Welcome = ({ onComplete }: Props) => {
  // Record whether the user has confirmed that they are happy to proceed
  // This gives them a chance to get audio set up, but also serves the
  // purpose of allowing us to play audio without it getting treated as
  // autoplay (which will by default not play any sounds if the user
  // has not interacted yet)
  const [startConfirmed, setStartConfirmed] = useState(false);

  const [image, setImage] =
    useState<"teaching" | "english" | "chinese">("teaching");

  const audioCallback = useDelayedAudio("audio/welcome.mp3");

  if (!startConfirmed) {
    return (
      <Row className="justify-content-center">
        <Col lg={8}>
          <Card>
            <Card.Body>
              <p>
                This site requires audio to work properly. Please confirm that
                you're ready to play audio.
              </p>
              <button
                onClick={() => {
                  setStartConfirmed(true);
                  setTimeout(() => setImage("english"), 3500);
                  setTimeout(() => setImage("chinese"), 6500);
                  audioCallback().then(() => {
                    setTimeout(onComplete, 2000);
                  });
                }}
              >
                Proceed
              </button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <>
      {image === "teaching" && (
        <div className="prompt-image">
          <div className="image-layer">
            <img
              src="https://static.langsnap.com/images/chinese_on_chalkboard.jpg"
              alt="Chinese characters on a blackboard"
            />
          </div>
        </div>
      )}

      {image === "english" && (
        <div className="prompt-image">
          <div className="image-layer">
            <img
              src="https://static.langsnap.com/images/english_speech_bubble.png"
              alt="English speech bubble"
            />
            <img
              className="overlay-appear"
              src="https://static.langsnap.com/images/negative_overlay.png"
              alt="negative"
            />
          </div>
        </div>
      )}

      {image === "chinese" && (
        <div className="prompt-image">
          <div className="image-layer">
            <img
              src="https://static.langsnap.com/images/chinese_speech_bubble.png"
              alt="Chinese speech bubble"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Welcome;
