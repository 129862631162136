import React, { useContext, useEffect, useState } from "react";
import { getFirestore, Firestore } from "firebase/firestore";
import * as Sentry from "@sentry/react";
import assertNever from "assert-never";
import seedrandom from "seedrandom";
import { computed, flow, makeObservable, observable, runInAction } from "mobx";

import { AudioAssets, Concept, Illustration, VocabularyEntry } from "./schema";
import { getFirebase } from "./firebase";
import { AuthContext } from "./AuthContext";
import { randomInt, ShuffledStream } from "./utils/shuffle";
import {
  ConceptLearningState,
  getConfidence,
  LearningResult,
  TimePeriodResult,
  updateConceptLearningState,
} from "./teaching/VocabularyState";
import {
  getConcepts,
  getIllustrations,
  getOrCreateUser,
  getVocabulary,
  setUserFields,
  setVocabularyEntryFields,
} from "./DataStore";
import { ConceptFilter, IllustrationFilter, UserState } from "./interfaces";

// We won't attempt to teach any concept unless it has at least this number
// of separate illustrations.
const minIllustrationsToTeach = 3;

// Some firebase-independent code that can be shared with the test stubs.
export class UserStoreBase {
  public vocabularyState: ConceptLearningState[] = [];

  // This stores every known illustration in the DB. This is wasteful, but
  // simpler and at current scale and probably performs better than any
  // alternative.
  public illustrations = new Map<string, Illustration>();

  public getConcept(conceptId: string) {
    for (const vocabularyEntry of this.vocabularyState) {
      if (vocabularyEntry.concept.id === conceptId) {
        return vocabularyEntry.concept;
      }
    }

    throw new Error(
      `Requested concept ID ${conceptId} missing from user state store`
    );
  }

  public getIllustrationById(id: string) {
    const illustration = this.illustrations.get(id);

    if (illustration === undefined) {
      throw new Error("Requested illustration by ID that doesn't exist");
    }

    return illustration;
  }

  public getRandomConcepts(
    count: number,
    filter?: ConceptFilter,
    rng?: seedrandom.PRNG
  ): Concept[] {
    const candidates = this.vocabularyState
      .map((state) => state.concept)
      .filter((concept) => {
        if (
          filter?.excludeConceptIds !== undefined &&
          filter.excludeConceptIds.includes(concept.id)
        ) {
          return false;
        }

        if (
          filter?.minIllustrations !== undefined &&
          concept.illustrations.length < filter.minIllustrations
        ) {
          return false;
        }

        return true;
      });

    const results: Concept[] = [];

    for (let i = 0; i < count; i++) {
      const index = randomInt(candidates.length - 1, rng);
      results.push(candidates[index]);
    }

    return results;
  }
}

export class UserStateStore extends UserStoreBase implements UserState {
  public loading: boolean;
  public welcomePageSeen: boolean;
  public error: Error | undefined;

  private uid: string;
  private firebaseDB: Firestore;

  constructor(firebaseDB: Firestore, uid: string) {
    super();

    this.uid = uid;
    this.firebaseDB = firebaseDB;

    this.loading = true;
    this.welcomePageSeen = false;
    this.vocabularyState = [];
    this.error = undefined;

    makeObservable(this, {
      loading: observable,
      error: observable,
      welcomePageSeen: observable,
      unlearnedConcepts: computed,
      vocabularySummary: computed,
      vocabularyState: observable,
      setConceptResult: flow,
    });

    try {
      this.loadFromFirebase();
    } catch (e) {
      this.reportError(e);
    }
  }

  private reportError(e: unknown) {
    if (e instanceof Error) {
      this.error = e;
    } else {
      this.error = new Error("Unknown error");
    }
  }

  public getConceptsForIllustration(illustrationId: string): Concept[] {
    return this.vocabularyState
      .filter((conceptState) => {
        if (conceptState.concept.category === "noun") {
          return conceptState.concept.illustrations.includes(illustrationId);
        } else if (conceptState.concept.category === "stative_verb") {
          return (
            conceptState.concept.illustrations.find(
              (currentIllustration) =>
                currentIllustration.illustration === illustrationId
            ) !== undefined
          );
        } else {
          return assertNever(conceptState.concept);
        }
      })
      .map((conceptState) => conceptState.concept);
  }

  // Get concepts that the user has not currently learned, to any level of competence.
  // TODO The naming is probably wrong here. We have a concept of "due" in the vocabulary
  // tracking, and what we're really doing here is finding the words that are due.
  public get unlearnedConcepts() {
    return this.vocabularyState
      .filter(
        (entry) => entry.concept.illustrations.length >= minIllustrationsToTeach
      )
      .map((entry) => ({
        vocabularyEntry: entry,
        confidence: getConfidence(entry),
      }))
      .filter(({ confidence }) => confidence.due.valueOf() < Date.now())
      .sort((a, b) => a.confidence.due.valueOf() - b.confidence.due.valueOf())
      .filter(
        ({ vocabularyEntry }) => vocabularyEntry.concept.audio !== undefined
      )
      .map(({ vocabularyEntry }) => vocabularyEntry.concept.id);
  }

  public get vocabularySummary() {
    const wordsSeen = this.vocabularyState.filter(
      (conceptLearningState) => conceptLearningState.results.length > 0
    ).length;

    const wordsConfident = Array.from(
      this.vocabularyState
        .map(getConfidence)
        .filter((confidence) => confidence.confidence > 0.2)
    ).length;

    return { wordsSeen, wordsConfident };
  }

  private async loadFromFirebase() {
    const userRecord = await getOrCreateUser(
      { firebaseDB: this.firebaseDB },
      this.uid
    );

    if (userRecord.exists()) {
      this.welcomePageSeen = userRecord.data().welcomePageSeen ?? false;
    }

    await this.refreshVocabularyState();

    runInAction(() => {
      this.loading = false;
    });
  }

  public async setWelcomePageSeen() {
    try {
      runInAction(() => (this.welcomePageSeen = true));
      await setUserFields({ firebaseDB: this.firebaseDB }, this.uid, {
        welcomePageSeen: true,
      });
    } catch (e) {
      this.reportError(e);
    }
  }

  public *setConceptResult(conceptId: string, result: LearningResult) {
    try {
      const conceptIndex = this.vocabularyState.findIndex(
        (learningState) => learningState.concept.id === conceptId
      );

      if (conceptIndex === -1) {
        // This shouldn't really happen, how have we tested a concept that isn't in our
        // user state?
        Sentry.captureEvent({
          message: `Attempted to set result for concept ${conceptId} but missing from vocabulary`,
          level: "error",
        });
      } else {
        const existingState = this.vocabularyState[conceptIndex];
        const updatedState = updateConceptLearningState(existingState, result);
        this.vocabularyState[conceptIndex] = updatedState;

        const update: Partial<VocabularyEntry> = {
          results: updatedState.results.map((result) => ({
            date: result.date.valueOf().toString(),
            score: result.score,
          })),
        };

        yield setVocabularyEntryFields(
          { firebaseDB: this.firebaseDB },
          this.uid,
          conceptId,
          update
        );
        yield this.refreshVocabularyState();
      }
    } catch (e) {
      this.reportError(e);
    }
  }

  private async refreshVocabularyState() {
    const dataStoreContext = { firebaseDB: this.firebaseDB };

    const conceptsSnapshotPromise = getConcepts(dataStoreContext);
    const vocabularySnapshotPromise = getVocabulary(dataStoreContext, this.uid);
    const illustrationSnapshotPromise = getIllustrations(dataStoreContext);

    const conceptTestHistory = new Map<string, TimePeriodResult[]>();

    const [conceptsSnapshot, vocabularySnapshot, illustrationSnapshot] =
      await Promise.all([
        conceptsSnapshotPromise,
        vocabularySnapshotPromise,
        illustrationSnapshotPromise,
      ]);

    vocabularySnapshot.forEach((vocabularyEntry) => {
      if (vocabularyEntry.results !== undefined) {
        conceptTestHistory.set(
          vocabularyEntry.conceptId,
          vocabularyEntry.results.map((result) => ({
            date: new Date(parseInt(result.date, 10)),
            score: result.score,
          }))
        );
      } else if (vocabularyEntry.lastSeen !== undefined) {
        // Have old schema that we can update.
        // TODO Remove this code at some point in the future when all users are updated.
        conceptTestHistory.set(vocabularyEntry.conceptId, [
          { date: new Date(parseInt(vocabularyEntry.lastSeen, 10)), score: 1 },
        ]);
      }
    });

    runInAction(() => {
      this.vocabularyState.length = 0;
      conceptsSnapshot.forEach((concept) => {
        this.vocabularyState.push({
          concept,
          results: conceptTestHistory.get(concept.id) ?? [],
        });
      });

      this.illustrations.clear();
      illustrationSnapshot.forEach((illustration) => {
        this.illustrations.set(illustration.id, illustration);
      });
    });
  }

  // Get a specific illustration of a concept, indexed by number
  //
  // We're loading too much non-user-related functionality into the UserStateContext
  // right now. The reason we're doing this is that this object more or less has to
  // load all the data from the DB anyway, so it's a convenient place to get hold
  // of things without sending a whole bunch of DB queries. But in the long term
  // this should probably be factored out into a class for non-user concept state
  // and a user-specific class.
  public getIllustration(concept: Concept, index: number): Illustration {
    if (index >= concept.illustrations.length) {
      throw new Error(
        `Requested out of range illustration, concept=${concept.id}, index=${index}`
      );
    }

    if (concept.category === "noun") {
      const illustrationID = concept.illustrations[index];
      const illustration = this.illustrations.get(illustrationID);

      if (illustration === undefined) {
        throw new Error(`Missing illustration ${illustrationID}`);
      }

      return illustration;
    } else if (concept.category === "stative_verb") {
      const illustrationID = concept.illustrations[index].illustration;

      const illustration = this.illustrations.get(illustrationID);

      if (illustration === undefined) {
        throw new Error(`Missing illustration ${illustrationID}`);
      }

      return illustration;
    } else {
      assertNever(concept);
    }
  }

  public getRandomIllustration(
    concept: Concept,
    filter?: IllustrationFilter,
    rng?: seedrandom.PRNG
  ) {
    if (concept.illustrations.length === 0) {
      throw new Error(
        `Attempted to get an illustration from a concept with no illustrations (conceptId=${concept.id})`
      );
    }

    const availableIndices = [...Array(concept.illustrations.length).keys()];
    const indexStream = new ShuffledStream(availableIndices, rng);

    for (let i = 0; i < concept.illustrations.length; i++) {
      const illustration = this.getIllustration(concept, indexStream.next());

      if (
        filter === undefined ||
        illustrationMatchesFilter(illustration, filter)
      ) {
        return illustration;
      }
    }

    // If we fall through here, then presumably no image satisfied the filter.
    // Just pick a random image and be done with it.
    return this.getIllustration(
      concept,
      randomInt(concept.illustrations.length - 1, rng)
    );
  }
}

const defaultUserState: UserState = {
  loading: true,
  welcomePageSeen: false,
  error: undefined,
  unlearnedConcepts: [],
  vocabularyState: [],
  vocabularySummary: { wordsSeen: 0, wordsConfident: 0 },
  setWelcomePageSeen: () => null,
  setConceptResult: function* () {},
  getConcept: () => {
    throw new Error("Store not initialized");
  },
  getConceptsForIllustration: () => [],
  getRandomConcepts: () => [],
  getIllustration: () => {
    throw new Error("Store not initialized");
  },
  getIllustrationById(id) {
    throw new Error("Store not initialized");
  },
  getRandomIllustration: () => {
    throw new Error("Store not intiialized");
  },
};

export const UserStateContext =
  React.createContext<UserState>(defaultUserState);

export interface Props {
  children: React.ReactNode;
}

export function WithUserState({ children }: Props) {
  const authContext = useContext(AuthContext);
  const [store, setStore] = useState<UserState>(defaultUserState);

  useEffect(() => {
    if (authContext.loggedIn) {
      const app = getFirebase();
      const firebaseDB = getFirestore(app);

      setStore(new UserStateStore(firebaseDB, authContext.uid));
    }
  }, [authContext]);

  return (
    <UserStateContext.Provider value={store}>
      {children}
    </UserStateContext.Provider>
  );
}

// Extract the correct audio for a concept. For a noun this is simple,
// because there's only one instantiation of each audio template. But for
// a stative verb, the audio will be separately instantiated for each
// possible concept being used as the subject to apply the stative verb
// to.
export function getAudio(
  userState: UserState,
  concept: Concept,
  k: keyof AudioAssets,
  illustration: Illustration
): string | undefined {
  if (concept.audio === undefined) {
    return undefined;
  }

  if (concept.category === "noun") {
    return concept.audio[k];
  } else if (concept.category === "stative_verb") {
    // The correct audio will depend on the subject active in the illustration.
    // Find the first noun concept the illustration is linked with to give us
    // the subject. This is a kludge and probably not good enough in general,
    // but should suffice for simple cases.
    const nounConcepts = userState
      .getConceptsForIllustration(illustration.id)
      .filter((concept) => concept.category === "noun");

    if (nounConcepts.length === 0) {
      Sentry.captureEvent({
        level: "error",
        message: "Stative verb illustration has no attached noun",
        extra: {
          conceptId: concept.id,
          illustrationId: illustration.id,
        },
      });
      return undefined;
    }

    const subjectID = nounConcepts[0].id;
    const conceptAudioEntry = concept.audio[k];

    if (Array.isArray(conceptAudioEntry)) {
      const correctAudio = conceptAudioEntry.find(
        (record) => record.subject === subjectID
      );

      return correctAudio?.audioFile;
    }

    return conceptAudioEntry;
  } else {
    assertNever(concept);
  }
}

function illustrationMatchesFilter(
  illustration: Illustration,
  filter: IllustrationFilter
): boolean {
  if (
    filter.excludeIds !== undefined &&
    filter.excludeIds.includes(illustration.id)
  ) {
    return false;
  }

  return true;
}
