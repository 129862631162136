import { useContext, useState } from "react";
import { Breadcrumb, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import { addConcept, getAllConcepts } from "../../AdminDataStore";
import { AdminStoreContext } from "../../AdminDataStoreContext";
import { ErrorCard } from "../ErrorCard";
import ConceptSelector from "./ConceptSelector";
import { Concept } from "../../schema";

export const ConceptAdd = () => {
  const [name, setName] = useState("");
  const [measureWord, setMeasureWord] = useState("");
  const [translations, setTranslations] = useState<Record<string, string>>({});
  const [state, setState] = useState<"idle" | "saving" | "saved" | "error">(
    "idle"
  );
  const [contrastingConcepts, setContrastingConcepts] = useState<Concept[]>([]);

  const adminStoreContext = useContext(AdminStoreContext);

  const conceptsResult = useQuery({
    queryKey: ["concepts", "all"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllConcepts(adminStoreContext);
      }
    },
  });

  const SuccessToast = () => <div>Successfully added concept</div>;

  const save = () => {
    if (adminStoreContext === undefined) {
      return;
    }

    setState("saving");

    addConcept(adminStoreContext, {
      category: "noun",
      name,
      measureWord,
      translations,
      contrastingConcepts: contrastingConcepts.map((concept) => concept.id),
    }).then(() => {
      toast(<SuccessToast />);
      setState("saved");
      setName("");
      setMeasureWord("");
      setTranslations({});
    });
  };

  if (adminStoreContext === undefined) {
    return <ErrorCard>Not logged in</ErrorCard>;
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          href="/admin"
          linkAs={Link}
          linkProps={{ to: "/admin" }}
        >
          Admin home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          href="/admin/concepts"
          linkAs={Link}
          linkProps={{ to: "/admin/concepts" }}
        >
          Concepts
        </Breadcrumb.Item>

        <Breadcrumb.Item active>Add</Breadcrumb.Item>
      </Breadcrumb>

      <Form>
        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Form.Select>
            <option>Noun</option>
            <option>Stative verb</option>
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Measure word</Form.Label>
          <Form.Control
            type="text"
            value={measureWord}
            onChange={(e) => setMeasureWord(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Translations</Form.Label>
          <table>
            <tbody>
              <tr>
                <td>en</td>
                <td>
                  <Form.Control
                    type="text"
                    value={translations.en ?? ""}
                    onChange={(e) =>
                      setTranslations({ ...translations, en: e.target.value })
                    }
                  />
                </td>
              </tr>
              <tr>
                <td>zh</td>
                <td>
                  <Form.Control
                    type="text"
                    value={translations.zh ?? ""}
                    onChange={(e) =>
                      setTranslations({ ...translations, zh: e.target.value })
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Form.Group>

        <Form.Group>
          <Form.Label>Contrasting concepts</Form.Label>
          {conceptsResult.data !== undefined && (
            <ConceptSelector
              concepts={conceptsResult.data}
              selectedConcepts={contrastingConcepts}
              onChange={setContrastingConcepts}
            />
          )}
        </Form.Group>

        <Button
          disabled={
            state === "saving" ||
            name === "" ||
            measureWord === "" ||
            !translations.en ||
            !translations.zh
          }
          onClick={save}
        >
          Save
        </Button>
      </Form>
    </>
  );
};

export default ConceptAdd;
