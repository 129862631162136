import { useAudio } from "../hooks/audio";

export interface Props {
  imageURL: string;
  audioURL?: string;
  onComplete: () => void;
  negative: boolean;
}

// An instruction page displays content, without requiring any input from
// the user (other than to agree to move forward / skip?)
export const Instruction = ({
  imageURL,
  audioURL,
  onComplete,
  negative,
}: Props) => {
  useAudio({ onComplete }, audioURL);

  return (
    <div className="prompt-image">
      <div className="image-layer">
        <img src={imageURL} alt="Instructions go here" />
        {negative && (
          <img
            src="https://static.langsnap.com/images/negative_overlay.png"
            className="overlay-appear"
            alt="negative"
          />
        )}
      </div>
    </div>
  );
};

export default Instruction;
