import { FirebaseApp, initializeApp } from "firebase/app";

let firebaseApp: FirebaseApp | undefined = undefined;

export const getFirebase = () => {
  if (firebaseApp === undefined) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
    };

    firebaseApp = initializeApp(firebaseConfig);
  }

  return firebaseApp;
};
