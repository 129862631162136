// The header / footer page structure for all admin pages.

import { getFirestore } from "firebase/firestore";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AdminStoreContext } from "../../AdminDataStoreContext";
import { getFirebase } from "../../firebase";

const firebaseApp = getFirebase();
const firebaseDB = getFirestore(firebaseApp);

export const AdminContainer = () => {
  return (
    <AdminStoreContext.Provider value={{ firebaseDB }}>
      <div className="App">
        <Container>
          <Row>
            <Col>
              <Card>
                <Outlet />
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer position="bottom-left" />
      </div>
    </AdminStoreContext.Provider>
  );
};

export default AdminContainer;
