import { useCallback, useContext, useMemo, useState } from "react";
import { assertNever } from "assert-never";
import * as Sentry from "@sentry/react";

import { UserState } from "../interfaces";
import { getAudio, UserStateContext } from "../UserStateContext";
import ConceptTest, { TestScore } from "./ConceptTest";
import Instruction from "./Instruction";
import TeachCharacters from "./TeachCharacters";
import { Concept, Illustration } from "../schema";
import { ShuffledStream } from "../utils/shuffle";
import { getIllustrationURL } from "../DataStore";
import LoadingCard from "./LoadingCard";

export interface Props {
  onComplete: () => void;
  conceptId: string;
}

interface IllustrateStep {
  step: "illustrate";
  illustration: Illustration;
  audioURL: string;
}

interface ShowCharactersStep {
  step: "show_characters";
  concept: Concept;
}

interface TestStep {
  step: "test";
}

type Step = IllustrateStep | ShowCharactersStep | TestStep;

// Get a collection of illustration objects that have been seen earlier
// in the program than the specified step. This helps to avoid repeats.
function getPreviouslySeenIllustrations(
  program: Step[],
  currentStepNumber: number
): Illustration[] {
  const results: Illustration[] = [];

  for (let index = 0; index < currentStepNumber; index++) {
    const step = program[index];

    if (step.step === "illustrate") {
      results.push(step.illustration);
    }
  }

  return results;
}

// Show the user illustrations of a concept for them to learn from, and then
// test to see whether they have learned it.
export const TeachConcept = ({ conceptId, onComplete }: Props) => {
  const userStateStore = useContext(UserStateContext);
  const [currentStepNumber, setCurrentStepNumber] = useState(0);

  const program = useMemo(
    () => initializeProgram(userStateStore, conceptId),
    [userStateStore, conceptId]
  );

  const registerTestResult = async (testScore: TestScore) => {
    await userStateStore.setConceptResult(conceptId, {
      success: testScore.numFailure == 0,
    });
  };

  const nextStep = useCallback(async () => {
    if (program === null) {
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 1000));

    if (currentStepNumber >= program.length - 1) {
      onComplete();
    } else {
      setCurrentStepNumber(currentStepNumber + 1);
    }
  }, [conceptId, currentStepNumber, onComplete, program, userStateStore]);

  if (program.length === 0) {
    // This branch shouldn't really happen, we should unmount when there's
    // nothing left to do.
    return <LoadingCard />;
  }

  const currentStep = program[currentStepNumber];

  if (currentStep.step === "illustrate") {
    return (
      <Instruction
        imageURL={getIllustrationURL(currentStep.illustration)}
        audioURL={currentStep.audioURL}
        negative={false}
        onComplete={nextStep}
        key={currentStepNumber}
      />
    );
  } else if (currentStep.step === "show_characters") {
    return (
      <TeachCharacters concept={currentStep.concept} onComplete={nextStep} />
    );
  } else if (currentStep.step === "test") {
    return (
      <ConceptTest
        conceptId={conceptId}
        onComplete={(testScore) => {
          registerTestResult(testScore);
          nextStep();
        }}
        excludeIllustrationIds={getPreviouslySeenIllustrations(
          program,
          currentStepNumber
        ).map((illustration) => illustration.id)}
      />
    );
  } else {
    assertNever(currentStep);
  }
};

function initializeProgram(
  userStateStore: UserState,
  conceptId: string
): Step[] {
  const concept = userStateStore.getConcept(conceptId);

  // Sample from illustrations without replacement.
  const availableIndices = new ShuffledStream([
    ...Array(concept.illustrations.length).keys(),
  ]);

  const illustrations = [
    userStateStore.getIllustration(concept, availableIndices.next()),
    userStateStore.getIllustration(concept, availableIndices.next()),
  ];

  const audioURLs = illustrations.map((illustration) =>
    getAudio(userStateStore, concept, "thisIs", illustration)
  );

  if (audioURLs[0] === undefined || audioURLs[1] === undefined) {
    Sentry.captureEvent({
      level: "error",
      message: "Missing audio for concept",
      extra: {
        conceptId: concept.id,
      },
    });

    throw new Error("Missing audio for concept");
  }

  return [
    {
      step: "illustrate",
      illustration: illustrations[0],
      audioURL: audioURLs[0],
    },
    {
      step: "show_characters",
      concept,
    },
    {
      step: "illustrate",
      illustration: illustrations[1],
      audioURL: audioURLs[1],
    },
    { step: "test" },
  ];
}

export default TeachConcept;
