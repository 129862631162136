import * as Sentry from "@sentry/react";
import { doc, getDoc } from "firebase/firestore";
import { DataStoreContext } from "./DataStore";

import { Concept, ConceptSchema } from "./schema";

export async function getConcept(
  dataStoreContext: DataStoreContext,
  conceptId: string
): Promise<Concept> {
  const concept = await getDoc(
    doc(dataStoreContext.firebaseDB, "concepts", conceptId)
  );

  if (!concept.exists()) {
    Sentry.captureEvent({
      message: "Concept doesn't exist in DB",
      level: "error",
      extra: {
        conceptId,
      },
    });
    throw new Error(`Concept ${conceptId} missing from DB`);
  }

  const data = concept.data();

  if (data === undefined) {
    throw new Error(`Concept ${conceptId} not found`);
  }

  return ConceptSchema.parse({ ...data, id: concept.id });
}
