import { useEffect, useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

export const LoadingCard = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timerHandle = setTimeout(() => setShow(true), 500);

    return () => {
      clearTimeout(timerHandle);
    };
  }, []);

  if (show) {
    return (
      <div className="card loading-card">
        <PuffLoader />
        <div className="message">Loading...</div>
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingCard;
