import { useState } from "react";
import { Col, Row } from "react-bootstrap";

import { useAudio, useDelayedAudio } from "../hooks/audio";

export interface Props {
  imageURLs: string[];
  promptAudioURL: string;

  // The correct answer, which is an index into the image URLs property.
  correctAnswer: number;

  answeredCorrectly: () => void;
  answeredIncorrectly: () => void;
}

const correctAudioEffectURL = "audio/effects/correct.mp3";
const incorrectAudioEffectURL = "audio/effects/incorrect.mp3";

// Show a question to the user with a multiple choice answer,
// where there is one correct answer.
export const MultipleChoiceQuestion = ({
  imageURLs,
  promptAudioURL,
  correctAnswer,
  answeredCorrectly,
  answeredIncorrectly,
}: Props) => {
  useAudio({}, promptAudioURL);
  const [answered, setAnswered] = useState(false);

  const playCorrectEffectAudio = useDelayedAudio(correctAudioEffectURL);
  const playIncorrectEffectAudio = useDelayedAudio(incorrectAudioEffectURL);

  const selectOption = (index: number) => {
    setAnswered(true);
    if (index === correctAnswer) {
      playCorrectEffectAudio().then(answeredCorrectly);
    } else {
      playIncorrectEffectAudio().then(answeredIncorrectly);
    }
  };

  return (
    <Row className="justify-content-center">
      <Col lg={8}>
        <div
          className={
            "question multiple-choice " +
            (imageURLs.length > 4 ? "four-column" : "two-column")
          }
        >
          {imageURLs.map((url, index) => (
            <img
              className={
                "question " +
                (answered ? "answered " : "") +
                (answered && index === correctAnswer ? "correct" : "incorrect")
              }
              src={url}
              onClick={() => selectOption(index)}
              key={index}
              alt={`Option ${index + 1}`}
            />
          ))}
        </div>
      </Col>
    </Row>
  );
};

export default MultipleChoiceQuestion;
