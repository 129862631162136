import { useAudio } from "../hooks/audio";

export interface Props {
  onComplete: () => void;
}

export const WelcomeBack = ({onComplete}: Props) => {
  useAudio({onComplete}, "audio/welcome_back.mp3");

  return (
    <div className="prompt-image">
      <div className="image-layer">
        <img src="https://static.langsnap.com/images/waving_hand.svg" alt="Waving hand emoji" />
      </div>
    </div>
  );
};

export default WelcomeBack;
