import { Card, Container } from "react-bootstrap";

export interface Props {
  error: Error;
  reset: () => void;
}

export default function ErrorPage({ error, reset }: Props) {
  return (
    <Container>
      <Card>
        <Card.Body>
          <Card.Title>An error occurred</Card.Title>
          <Card.Text>
            An error occurred while loading this page: {error.message}
          </Card.Text>

          <button onClick={reset}>Try again</button>
        </Card.Body>
      </Card>
    </Container>
  );
}
