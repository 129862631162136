import seedrandom from "seedrandom";

export function randomInt(max: number, rng?: seedrandom.PRNG) {
  if (rng !== undefined) {
    return Math.floor(rng() * (max + 1));
  } else {
    return Math.floor(Math.random() * (max + 1));
  }
}

// Make an infinite stream of objects taken from the items array, in a shuffled
// order but ensuring we go through each item once before we repeat.
export class ShuffledStream<T> {
  private items: T[];
  private currentBatch: T[];
  private rng: seedrandom.PRNG;

  constructor(items: T[], rng?: seedrandom.PRNG) {
    this.items = items;
    this.currentBatch = this.drawNewBatch();

    this.rng = rng ?? seedrandom();
  }

  private drawNewBatch(): T[] {
    const result: T[] = [];
    const remaining = this.items.slice();

    while (remaining.length > 0) {
      const index = randomInt(remaining.length - 1, this.rng);
      result.push(remaining.splice(index, 1)[0]);
    }

    return result;
  }

  public next(): T {
    const value = this.currentBatch.pop();

    if (value === undefined) {
      this.currentBatch = this.drawNewBatch();

      const newValue = this.currentBatch.pop();
      if (newValue === undefined) {
        throw new Error("Attempting to draw from empty array");
      }

      return newValue;
    } else {
      return value;
    }
  }
}
