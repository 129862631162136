import { useContext, useEffect, useRef, useState, useMemo } from "react";
import * as Sentry from "@sentry/react";
import assertNever from "assert-never";

import { Concept, Illustration } from "../schema";
import { UserStateContext, getAudio } from "../UserStateContext";
import { UserState } from "../interfaces";
import YesNoQuestion from "./YesNoQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import { randomInt, ShuffledStream } from "../utils/shuffle";
import {
  MultipleChoiceTestSpec,
  MultipleChoiceTestState,
  drawMultipleChoiceTest,
} from "../teaching/MultipleChoiceTest";
import { getIllustrationURL } from "../DataStore";
import { ErrorCard } from "./ErrorCard";

interface TestOutcome {
  success: boolean;
}

export interface TestScore {
  numSuccess: number;
  numFailure: number;
}

export interface Props {
  conceptId: string;

  // A list of image IDs that we want to exclude from being used in the test.
  // This is best-effort, if we can't get enough images then we might end up
  // using one of these.
  excludeIllustrationIds: string[];

  onComplete: (score: TestScore) => void;
}

interface YesNoTestSpec {
  testType: "yesNo";

  // Whether this illustration is a match ("yes") or not ("no")
  match: boolean;
}

interface YesNoTestState extends YesNoTestSpec {
  illustration: Illustration;
}

type TestSpec = MultipleChoiceTestSpec | YesNoTestSpec;
type TestState = MultipleChoiceTestState | YesNoTestState;

// The program of test types that we're going to run. This will be shuffled
// before the user actually sees it.
const program: TestSpec[] = [
  { testType: "multipleChoice" },
  { testType: "multipleChoice" },
  { testType: "yesNo", match: true },
  { testType: "yesNo", match: true },
  { testType: "yesNo", match: false },
];

// Check that a concept has been learned by showing some yes / no
// questions.
//
// Currently we just keep asking until we think the user has got the
// point, but in future we should probably have the facility to put
// a difficult concept on hold and come back to it.
export const ConceptTest = (props: Props) => {
  const userStateStore = useContext(UserStateContext);
  const [testOutcomes, setTestOutcomes] = useState<TestOutcome[]>([]);
  const [testState, setTestState] = useState<TestState | undefined>(undefined);
  const programSequence = useRef(new ShuffledStream(program));

  const { concept, contrastingConcept } = initializeConcepts(
    userStateStore,
    props.conceptId
  );

  const illustrationIDstream = useMemo(
    () => getIllustrationIDStream(concept),
    [concept]
  );

  useEffect(() => {
    const testSpec = programSequence.current.next();
    if (testSpec.testType === "multipleChoice") {
      setTestState(
        drawMultipleChoiceTest(
          userStateStore,
          concept,
          contrastingConcept,
          illustrationIDstream
        )
      );
    } else if (testSpec.testType === "yesNo") {
      drawYesNoTest(contrastingConcept, testSpec.match);
    } else {
      assertNever(testSpec);
    }
  }, []);

  const drawYesNoTest = (contrastingConcept: Concept, match: boolean) => {
    if (match) {
      setTestState({
        testType: "yesNo",
        match,
        illustration: userStateStore.getIllustrationById(
          illustrationIDstream.next()
        ),
      });
    } else {
      setTestState({
        testType: "yesNo",
        match,
        illustration: userStateStore.getIllustration(
          contrastingConcept,
          randomInt(contrastingConcept.illustrations.length - 1)
        ),
      });
    }
  };

  const advanceTest = (outcomes: TestOutcome[]) => {
    if (concept === null || contrastingConcept === null) {
      return;
    }

    const numSuccess = outcomes.filter((outcome) => outcome.success).length;
    const numFailure = outcomes.filter((outcome) => !outcome.success).length;
    if (numSuccess > 2) {
      props.onComplete({ numSuccess, numFailure });
    } else {
      setTestOutcomes(outcomes);

      const testSpec = programSequence.current.next();

      if (testSpec.testType === "multipleChoice") {
        setTestState(
          drawMultipleChoiceTest(
            userStateStore,
            concept,
            contrastingConcept,
            illustrationIDstream
          )
        );
      } else if (testSpec.testType === "yesNo") {
        drawYesNoTest(contrastingConcept, testSpec.match);
      } else {
        assertNever(testSpec);
      }
    }
  };

  if (testState === undefined || concept === null) {
    return <p>Loading...</p>;
  }

  if (
    concept.audio === undefined ||
    concept.audio.isThis === undefined ||
    concept.audio.correctThisIs === undefined ||
    concept.audio.correctThisIsNot === undefined ||
    concept.audio.incorrectThisIs === undefined ||
    concept.audio.incorrectThisIsNot === undefined ||
    concept.audio.whichPictureIs === undefined
  ) {
    return <ErrorCard>The data for this concept is unavailable</ErrorCard>;
  }

  const answeredCorrectly = () => {
    const newTestOutcomes = testOutcomes.slice();
    newTestOutcomes.push({ success: true });
    advanceTest(newTestOutcomes);
  };

  const answeredIncorrectly = () => {
    const newTestOutcomes = testOutcomes.slice();
    newTestOutcomes.push({ success: false });
    advanceTest(newTestOutcomes);
  };

  if (testState.testType === "multipleChoice") {
    const whichPictureIs = getAudio(
      userStateStore,
      concept,
      "whichPictureIs",
      testState.illustrations[testState.correctAnswer]
    );

    if (whichPictureIs === undefined) {
      return <ErrorCard>Missing audio for this question</ErrorCard>;
    }

    return (
      <MultipleChoiceQuestion
        key={testOutcomes.length}
        imageURLs={testState.illustrations.map(getIllustrationURL)}
        correctAnswer={testState.correctAnswer}
        answeredCorrectly={answeredCorrectly}
        answeredIncorrectly={answeredIncorrectly}
        promptAudioURL={whichPictureIs}
      />
    );
  } else if (testState.testType === "yesNo") {
    let correctAudioURL: string | undefined;
    let incorrectAudioURL: string | undefined;

    if (testState.match) {
      correctAudioURL = getAudio(
        userStateStore,
        concept,
        "correctThisIs",
        testState.illustration
      );
      incorrectAudioURL = getAudio(
        userStateStore,
        concept,
        "incorrectThisIs",
        testState.illustration
      );
    } else {
      correctAudioURL = getAudio(
        userStateStore,
        concept,
        "correctThisIsNot",
        testState.illustration
      );
      incorrectAudioURL = getAudio(
        userStateStore,
        concept,
        "incorrectThisIsNot",
        testState.illustration
      );
    }

    const promptAudioURL = getAudio(
      userStateStore,
      concept,
      "isThis",
      testState.illustration
    );

    if (
      correctAudioURL === undefined ||
      incorrectAudioURL === undefined ||
      promptAudioURL === undefined
    ) {
      Sentry.captureEvent({
        level: "error",
        message: "Missing audio for concept",
        extra: {
          conceptId: concept.id,
          illustrationId: testState.illustration.id,
        },
      });

      return <ErrorCard>Missing Audio data</ErrorCard>;
    }

    return (
      <YesNoQuestion
        // We want to forcibly remount this component any time we have
        // changed to a conceptually new question, regardless of whether
        // any of the prompt or answers have chagned. This is because we
        // want to trigger a new audio play. Using the length of the outcomes
        // may be a bit messy, but we're probably going to have to refactor
        // all the state in this component anyway.
        key={testOutcomes.length}
        answeredCorrectly={answeredCorrectly}
        answeredIncorrectly={answeredIncorrectly}
        imageURL={getIllustrationURL(testState.illustration)}
        correctAnswer={testState.match}
        correctAudioURL={correctAudioURL}
        incorrectAudioURL={incorrectAudioURL}
        promptAudioURL={promptAudioURL}
      />
    );
  } else {
    assertNever(testState);
  }
};

function initializeConcepts(
  userStateStore: UserState,
  conceptId: string
): {
  concept: Concept;
  contrastingConcept: Concept;
} {
  const concept = userStateStore.getConcept(conceptId);

  if (
    concept.audio === undefined ||
    concept.audio.isThis === undefined ||
    concept.audio.correctThisIs === undefined ||
    concept.audio.correctThisIsNot === undefined ||
    concept.audio.incorrectThisIs === undefined ||
    concept.audio.incorrectThisIsNot === undefined
  ) {
    Sentry.captureEvent({
      message: "Concept has missing audio",
      extra: { audio: concept.audio },
    });
  }

  if (
    concept.contrastingConcepts !== undefined &&
    concept.contrastingConcepts.length > 0
  ) {
    const contrastingConcept = userStateStore.getConcept(
      concept.contrastingConcepts[0]
    );

    return { concept, contrastingConcept };
  } else {
    Sentry.captureEvent({
      message: "Concept with missing contrasting concept",
      extra: { conceptId: concept.id },
    });

    throw new Error("Concept with missing contrasting concept");
  }
}

// Get a stream of random illustration IDs that can be used. This will skip
// illustrations that the parent component told us to exclude, and also avoid
// unnecessarily repeating images (as would happen if we just draw randomly
// with replacement at each step).
function getIllustrationIDStream(concept: Concept): ShuffledStream<string> {
  let illustrationIDs: string[];

  if (concept.category === "noun") {
    illustrationIDs = [...concept.illustrations];
  } else if (concept.category === "stative_verb") {
    illustrationIDs = concept.illustrations.map(
      (record) => record.illustration
    );
  } else {
    assertNever(concept);
  }

  return new ShuffledStream(illustrationIDs);
}

export default ConceptTest;
