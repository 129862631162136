import { useContext } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { getAllConcepts } from "../../AdminDataStore";
import { AdminStoreContext } from "../../AdminDataStoreContext";
import { ErrorCard } from "../ErrorCard";
import LoadingCard from "../LoadingCard";

// List of concepts for admin users to edit
export default function Concepts() {
  const adminStoreContext = useContext(AdminStoreContext);

  const conceptsResult = useQuery({
    queryKey: ["concepts", "all"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllConcepts(adminStoreContext);
      }
    },
  });

  if (adminStoreContext === undefined) {
    return <ErrorCard>Not logged in</ErrorCard>;
  }

  if (conceptsResult.isError) {
    return <ErrorCard>Error fetching data</ErrorCard>;
  }

  if (conceptsResult.isLoading || conceptsResult.data === undefined) {
    return <LoadingCard />;
  }

  const conceptList = conceptsResult.data.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          href="/admin"
          linkAs={Link}
          linkProps={{ to: "/admin" }}
        >
          Admin home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Concepts</Breadcrumb.Item>
      </Breadcrumb>

      <Link to="/admin/concepts/add">Add</Link>

      <table className="concept-admin">
        <tbody>
          {conceptList.map((concept) => (
            <tr key={concept.id}>
              <td>{concept.category}</td>
              <td>
                <Link to={`/admin/concepts/${concept.id}`}>{concept.name}</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
