import { z } from "zod";

export const UserSchema = z.object({
  id: z.string(),
  welcomePageSeen: z.optional(z.boolean()),
});

export type User = z.infer<typeof UserSchema>;

export const AudioAssetsSchema = z.object({
  name: z.optional(z.string()),
  thisIs: z.optional(z.string()),
  isThis: z.optional(z.string()),
  correctThisIs: z.optional(z.string()),
  incorrectThisIs: z.optional(z.string()),
  correctThisIsNot: z.optional(z.string()),
  incorrectThisIsNot: z.optional(z.string()),
  whichPictureIs: z.optional(z.string()),
  thisPictureIs: z.optional(z.string()),
});

// A single audio record (e.g. a particular sentence template) expanded
// for a particular subject variable.
const TemplatedAudioSchema = z.object({
  subject: z.string(),
  audioFile: z.string(),
});

export type TemplatedAudio = z.infer<typeof TemplatedAudioSchema>;

export const TemplatedAudioAssetsSchema = z.object({
  name: z.optional(z.string()),
  thisIs: z.optional(z.array(TemplatedAudioSchema)),
  isThis: z.optional(z.array(TemplatedAudioSchema)),
  correctThisIs: z.optional(z.array(TemplatedAudioSchema)),
  incorrectThisIs: z.optional(z.array(TemplatedAudioSchema)),
  correctThisIsNot: z.optional(z.array(TemplatedAudioSchema)),
  incorrectThisIsNot: z.optional(z.array(TemplatedAudioSchema)),
  whichPictureIs: z.optional(z.array(TemplatedAudioSchema)),
  thisPictureIs: z.optional(z.array(TemplatedAudioSchema)),
});

export type AudioAssets = z.infer<typeof AudioAssetsSchema>;
export type TemplatedAudioAssets = z.infer<typeof TemplatedAudioAssetsSchema>;

const CoreSchema = z.object({
  id: z.string(),
  name: z.string(),
  measureWord: z.optional(z.string()),
  translations: z.record(z.string()),

  // Contrasting concepts are intended to be things that clearly
  // relate and contrast to this concept. We use them to provide
  // a clearly wrong answer in a visual way.
  //
  // We can have multiple contrasting concepts. We could
  // almost treat any other concept as a contrasting concept
  // (since they are supposed to all be distinct) but the hope
  // is that there's some extra conceptual value out of showing
  // something related-but-opposite rather than any random thing.
  // If we want to use random decoy concepts we can just draw
  // random concepts.
  contrastingConcepts: z.optional(z.array(z.string())),

  descriptions: z.optional(
    z.object({
      default: z.string(),
    })
  ),
});

export const NounSchema = z
  .object({
    category: z.literal("noun"),
    illustrations: z.array(z.string()),
    audio: z.optional(AudioAssetsSchema),
  })
  .merge(CoreSchema);

export const StativeVerbSchema = z
  .object({
    category: z.literal("stative_verb"),

    illustrations: z.array(
      z.object({
        subject: z.string(),
        illustration: z.string(),
      })
    ),

    audio: z.optional(TemplatedAudioAssetsSchema),
  })
  .merge(CoreSchema);

export const ConceptSchema = z.discriminatedUnion("category", [
  NounSchema,
  StativeVerbSchema,
]);

export type StativeVerb = z.infer<typeof StativeVerbSchema>;
export type Noun = z.infer<typeof NounSchema>;
export type Concept = z.infer<typeof ConceptSchema>;

export const IllustrationSchema = z.object({
  id: z.string(),
  url: z.string(),
});

export type Illustration = z.infer<typeof IllustrationSchema>;

export const VocabularyEntrySchema = z.object({
  conceptId: z.string(),
  lastSeen: z.optional(z.string()),
  results: z.optional(
    z.array(
      z.object({
        date: z.string(),
        score: z.number(),
      })
    )
  ),
});

export type VocabularyEntry = z.infer<typeof VocabularyEntrySchema>;
