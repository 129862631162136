import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useAudio, useDelayedAudio } from "../hooks/audio";

export interface Props {
  imageURL: string;
  promptAudioURL: string;

  correctAudioURL: string;
  // A message that will be played if the user picks the right answer

  incorrectAudioURL: string;
  // A message that will be played if the user picks the wrong answer

  correctAnswer: boolean;
  answeredCorrectly: () => void;
  answeredIncorrectly: () => void;
}

const correctAudioEffectURL = "audio/effects/correct.mp3";
const incorrectAudioEffectURL = "audio/effects/incorrect.mp3";

// Present a yes / no question to the user with a single image and an
// audio that they should judge to correspond to a true / false answer.
export const YesNoQuestion = ({
  imageURL,
  promptAudioURL,
  correctAudioURL,
  incorrectAudioURL,
  correctAnswer,
  answeredCorrectly,
  answeredIncorrectly,
}: Props) => {
  useAudio({}, promptAudioURL);
  const [answered, setAnswered] = useState(false);

  const playCorrectMessageAudio = useDelayedAudio(correctAudioURL);
  const playIncorrectMessageAudio = useDelayedAudio(incorrectAudioURL);
  const playCorrectEffectAudio = useDelayedAudio(correctAudioEffectURL);
  const playIncorrectEffectAudio = useDelayedAudio(incorrectAudioEffectURL);

  return (
    <Row className="justify-content-center">
      <Col lg={8}>
        <div className="question yes-no">
          <img src={imageURL} alt="" />
          <div className="option-button-group">
            <button
              className={
                answered
                  ? "answered " +
                    (correctAnswer === true ? "incorrect" : "correct")
                  : ""
              }
              onClick={() => {
                setAnswered(true);
                if (correctAnswer === false) {
                  playCorrectEffectAudio().then(() =>
                    playCorrectMessageAudio().then(answeredCorrectly)
                  );
                } else {
                  playIncorrectEffectAudio().then(() =>
                    playIncorrectMessageAudio().then(answeredIncorrectly)
                  );
                }
              }}
            >
              <span className="material-symbols-outlined">close</span>
            </button>
            <button
              className={
                answered
                  ? "answered " +
                    (correctAnswer === false ? "incorrect" : "correct")
                  : ""
              }
              onClick={() => {
                setAnswered(true);
                if (correctAnswer === true) {
                  playCorrectEffectAudio().then(() =>
                    playCorrectMessageAudio().then(answeredCorrectly)
                  );
                } else {
                  playIncorrectEffectAudio().then(() =>
                    playIncorrectMessageAudio().then(answeredIncorrectly)
                  );
                }
              }}
            >
              <span className="material-symbols-outlined">check</span>
            </button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default YesNoQuestion;
