import { useContext, useState } from "react";
import { Breadcrumb, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { setConceptsForIllustration } from "../../AdminDataStore";
import { getAllConcepts, getAllIllustrations } from "../../AdminDataStore";
import { AdminStoreContext } from "../../AdminDataStoreContext";
import { Concept, Illustration } from "../../schema";
import EditableConceptList from "./EditableConceptList";
import assertNever from "assert-never";
import { ErrorCard } from "../ErrorCard";

const pageSize = 5;

export default function Illustrations() {
  const queryClient = useQueryClient();
  const [pageNumber, setPageNumber] = useState(0);
  const adminStoreContext = useContext(AdminStoreContext);

  const illustrationResult = useQuery({
    queryKey: ["adminIllustrationList"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllIllustrations(adminStoreContext);
      }
    },
  });

  const conceptsResult = useQuery({
    queryKey: ["concepts", "all"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllConcepts(adminStoreContext);
      }
    },
  });

  if (adminStoreContext === undefined) {
    return <ErrorCard>Not logged in</ErrorCard>;
  }

  const conceptsForIllustration = (illustration: Illustration): Concept[] => {
    if (!conceptsResult.isSuccess || conceptsResult.data === undefined) {
      return [];
    }

    return conceptsResult.data.filter((concept) => {
      if (concept.category === "noun") {
        return concept.illustrations.includes(illustration.id);
      } else if (concept.category === "stative_verb") {
        return (
          concept.illustrations.find(
            (compareIllustration) =>
              compareIllustration.illustration === illustration.id
          ) !== undefined
        );
      } else {
        assertNever(concept);
      }
    });
  };

  const pageIllustrations =
    illustrationResult.data &&
    illustrationResult.data.slice(
      pageNumber * pageSize,
      (pageNumber + 1) * pageSize
    );

  const paginationItem = (paginationItemNumber: number) => (
    <Pagination.Item
      key={paginationItemNumber}
      active={pageNumber === paginationItemNumber}
      onClick={() => setPageNumber(paginationItemNumber)}
    >
      {paginationItemNumber + 1}
    </Pagination.Item>
  );

  const paginationItems: React.ReactElement[] = [];

  if (illustrationResult.data !== undefined) {
    for (let i = 0; i < illustrationResult.data.length / pageSize; i++) {
      paginationItems.push(paginationItem(i));
    }
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          href="/admin"
          linkAs={Link}
          linkProps={{ to: "/admin" }}
        >
          Admin home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Illustrations</Breadcrumb.Item>
      </Breadcrumb>
      <p>
        <Link to="./add">Add</Link>
      </p>
      <table className="illustration-admin">
        <tbody>
          {pageIllustrations !== undefined &&
            conceptsResult.isSuccess &&
            conceptsResult.data !== undefined &&
            pageIllustrations.map((illustration) => (
              <tr key={illustration.id}>
                <td>
                  <img
                    className="illustration-sample"
                    src={`https://static.langsnap.com/${illustration.url}`}
                    alt={`Sample ${illustration.id}`}
                  />
                </td>
                <td>
                  <EditableConceptList
                    concepts={conceptsResult.data ?? []}
                    selectedConcepts={conceptsForIllustration(illustration)}
                    onConceptsUpdated={async (newConcepts) => {
                      await setConceptsForIllustration(
                        adminStoreContext,
                        illustration,
                        newConcepts
                      );
                      queryClient.invalidateQueries({
                        queryKey: ["adminIllustrationsListConcepts"],
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div>
        <Pagination>{paginationItems}</Pagination>
      </div>
    </>
  );
}
