// Abstraction to the Firebase data store for things that the admin
// user wants to be able to do that shouldn't be exposed to unprivileged
// users.
//
// This doesn't feel like the right interface long term, we might need more
// abstraction and / or we might need a less sharp distinction between what
// an admin user can do and what an ordinary user can do (e.g. if there's
// some overlap or admins can write what ordinary users can only read).

import {
  addDoc,
  collection,
  doc,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import * as Sentry from "@sentry/react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";

import {
  Concept,
  ConceptSchema,
  Illustration,
  IllustrationSchema,
  Noun,
} from "./schema";
import { getConcept } from "./ConceptStore";
import { DataStoreContext } from "./DataStore";
import assertNever from "assert-never";

const staticDataBucket = "static.langsnap.com";

// Set the list of concepts that will apply to a given illustration.
// This overwrites the existing list, so any concepts not listed here
// will be removed.
export async function setConceptsForIllustration(
  storeContext: DataStoreContext,
  illustration: Illustration,
  concepts: Concept[]
) {
  // First find list of concepts that include this illustration;
  // some of these might need to have this illustration removed
  const q = query(
    collection(storeContext.firebaseDB, "concepts"),
    where("illustrations", "array-contains", illustration.id)
  );

  const snapshot = await getDocs(q);

  const conceptIDsToRemoveFrom: string[] = [];
  const conceptIDsToAddTo: string[] = concepts.map((concept) => concept.id);

  snapshot.forEach((doc) => {
    if (concepts.find((concept) => concept.id === doc.id) === undefined) {
      conceptIDsToRemoveFrom.push(doc.id);
    }

    const existingIndex = conceptIDsToAddTo.findIndex((id) => doc.id === id);
    if (existingIndex !== -1) {
      conceptIDsToAddTo.splice(existingIndex, 1);
    }
  });

  for (const removeID of conceptIDsToRemoveFrom) {
    // TODO Fix copy and paste below
    const concept = await getConcept(storeContext, removeID);
    if (concept.category === "noun") {
      const updatedIllustrations = concept.illustrations.slice();

      const existingIndex = updatedIllustrations.findIndex(
        (currentIllustrationID) => currentIllustrationID === illustration.id
      );

      if (existingIndex !== -1) {
        updatedIllustrations.splice(existingIndex, 1);
      } else {
        Sentry.captureEvent({
          level: "warning",
          message: "Attempted to remove illustration that doesn't exist",
          extra: {
            conceptID: removeID,
            illustrationID: illustration.id,
          },
        });
      }

      const updates: Partial<Noun> = {
        illustrations: updatedIllustrations,
      };
      await updateConcept(storeContext, concept.id, updates);
    } else if (concept.category === "stative_verb") {
      const updatedIllustrations = concept.illustrations.slice();

      const existingIndex = updatedIllustrations.findIndex(
        (currentIllustration) =>
          currentIllustration.illustration === illustration.id
      );

      if (existingIndex !== -1) {
        updatedIllustrations.splice(existingIndex, 1);
      } else {
        Sentry.captureEvent({
          level: "warning",
          message: "Attempted to remove illustration that doesn't exist",
          extra: {
            conceptID: removeID,
            illustrationID: illustration.id,
          },
        });
      }
    } else {
      assertNever(concept);
    }
  }

  for (const addID of conceptIDsToAddTo) {
    const concept = await getConcept(storeContext, addID);
    if (concept.category === "noun") {
      const updatedIllustrations = concept.illustrations.slice();

      if (
        updatedIllustrations.find((id) => id === illustration.id) === undefined
      ) {
        updatedIllustrations.push(illustration.id);

        await updateConcept(storeContext, concept.id, {
          illustrations: updatedIllustrations,
        });
      }
    } else if (concept.category === "stative_verb") {
      const updatedIllustrations = concept.illustrations.slice();

      if (
        updatedIllustrations.find(
          (record) => record.illustration === illustration.id
        ) === undefined
      ) {
        // We want to assign a subject for this illustration to apply to. We just take
        // the first noun concept. This isn't really reliable in general, but should work
        // for simple cases for now.
        const nounConcepts = concepts.filter(
          (concept) => concept.category === "noun"
        );

        if (nounConcepts.length === 0) {
          Sentry.captureEvent({
            level: "warning",
            message:
              "Tried to add a stative verb illustration with no corresponding concept",
            extra: {
              conceptId: concept.id,
            },
          });
          return;
        }

        updatedIllustrations.push({
          subject: nounConcepts[0].id,
          illustration: illustration.id,
        });

        await updateConcept(storeContext, concept.id, {
          illustrations: updatedIllustrations,
        });
      }
    } else {
      assertNever(concept);
    }
  }
}

export async function getAllConcepts(
  storeContext: DataStoreContext
): Promise<Concept[]> {
  const conceptSnapshot = await getDocs(
    collection(storeContext.firebaseDB, "concepts")
  );

  const concepts: Concept[] = [];
  conceptSnapshot.forEach((doc) => {
    const concept = ConceptSchema.parse({
      ...doc.data(),
      id: doc.id,
    });

    concepts.push(concept);
  });

  return concepts;
}

export async function getAllIllustrations(
  storeContext: DataStoreContext,
  limits?: { count: number; startAfter?: string }
): Promise<Illustration[]> {
  let querySet: Query<DocumentData> = collection(
    storeContext.firebaseDB,
    "illustrations"
  );

  try {
    if (limits !== undefined) {
      if (limits.startAfter !== undefined) {
        querySet = query(
          querySet,
          orderBy("__name__"),
          limit(limits.count),
          startAfter(limits.startAfter)
        );
      } else {
        querySet = query(querySet, limit(limits.count));
      }
    }
  } catch (e) {
    console.error("Caught error", e);
    throw e;
  }

  const illustrationSnapshot = await getDocs(querySet);

  const illustrations: Illustration[] = [];
  illustrationSnapshot.forEach((doc) => {
    const illustration = IllustrationSchema.parse({
      ...doc.data(),
      id: doc.id,
    });
    illustrations.push(illustration);
  });

  return illustrations;
}

// Add a new concept to the DB. This won't necessarily have every field populated,
// but should have enough that the concept is identifiable.
export async function addConcept(
  storeContext: DataStoreContext,
  concept: Partial<Omit<Concept, "id">>
): Promise<void> {
  const docContents = {
    category: concept.category,
    name: concept.name,
    measureWord: concept.measureWord,
    translations: concept.translations,
    illustrations: [],
    contrastingConcepts: concept.contrastingConcepts ?? [],
  };

  await addDoc(collection(storeContext.firebaseDB, "concepts"), docContents);
}

export async function updateConcept(
  storeContext: DataStoreContext,
  conceptId: string,
  fields: Partial<Concept>
): Promise<void> {
  const docRef = doc(storeContext.firebaseDB, "concepts", conceptId);

  await updateDoc(docRef, fields);
}

export async function saveImage(
  storeContext: DataStoreContext,
  s3: S3Client,
  fileName: string,
  fileContents: Buffer
) {
  const imageURL = `images/${fileName}`;

  const command = new PutObjectCommand({
    Bucket: staticDataBucket,
    Key: imageURL,
    Body: fileContents,
  });

  await s3.send(command);

  const illustrationCollection = collection(
    storeContext.firebaseDB,
    "illustrations"
  );

  await addDoc(illustrationCollection, {
    url: imageURL,
  });
}
