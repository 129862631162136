import { useState } from "react";

import { Concept } from "../../schema";
import ConceptSelector from "./ConceptSelector";

export interface Props {
  concepts: Concept[];
  selectedConcepts: Concept[];
  onConceptsUpdated: (selectedConcepts: Concept[]) => void;
}

// A list that holds a subset of the existing list of concepts,
// and allows the selected subset to be edited.
export default function EditableConceptList({
  concepts,
  selectedConcepts,
  onConceptsUpdated,
}: Props) {
  const [editing, setEditing] = useState(false);
  const [editingConcepts, setEditingConcepts] = useState<Concept[]>([]);

  if (editing) {
    return (
      <>
        <ConceptSelector
          concepts={concepts}
          selectedConcepts={editingConcepts}
          onChange={setEditingConcepts}
        />
        <button onClick={() => setEditing(false)}>Cancel</button>
        <button
          onClick={() => {
            setEditing(false);
            onConceptsUpdated(editingConcepts);
          }}
        >
          Save
        </button>
      </>
    );
  } else {
    return (
      <>
        <p>{selectedConcepts.map((concept) => concept.name).join(", ")}</p>
        <button
          onClick={() => {
            setEditing(true);
            setEditingConcepts(selectedConcepts);
          }}
        >
          Edit
        </button>
      </>
    );
  }
}
