import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useAudio } from "../hooks/audio";

import { Concept } from "../schema";

export interface Props {
  onComplete: () => void;
  concept: Concept;
}

export const TeachCharacters = ({ concept, onComplete }: Props) => {
  useAudio({ onComplete }, concept.audio?.name);

  useEffect(() => {
    if (concept.audio?.name === undefined) {
      // If we don't have an audio to play here, move on after a fixed amount
      // of time.
      const timeoutID = setTimeout(onComplete, 3000);

      return () => clearTimeout(timeoutID);
    }
  }, []);

  return (
    <Row className="justify-content-center">
      <Col lg={6} sm={12}>
        <div className="card">
          <div className="teaching word primary-focus">{concept.translations.zh}</div>
        </div>
      </Col>
    </Row>
  );
};

export default TeachCharacters;
