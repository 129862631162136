import { useContext } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { AdminStoreContext } from "../AdminDataStoreContext";
import { getAllConcepts, getAllIllustrations } from "../AdminDataStore";

export const AdminHomePage = () => {
  const adminStoreContext = useContext(AdminStoreContext);

  const illustrationsResult = useQuery({
    queryKey: ["adminHomePageIllustrations"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllIllustrations(adminStoreContext);
      }
    },
  });

  const conceptsResult = useQuery({
    queryKey: ["concepts", "all"],
    queryFn: () => {
      if (adminStoreContext !== undefined) {
        return getAllConcepts(adminStoreContext);
      }
    },
  });

  return (
    <div>
      <ul>
        <li>
          <Link to="./illustrations">Illustrations</Link>
        </li>
        <li>
          <Link to="./concepts">Concepts</Link>
        </li>
      </ul>

      {illustrationsResult.isSuccess &&
        illustrationsResult.data !== undefined &&
        conceptsResult.isSuccess &&
        conceptsResult.data !== undefined && (
          <p>
            The database has {illustrationsResult.data.length} illustrations of{" "}
            {conceptsResult.data.length} concepts.
          </p>
        )}
    </div>
  );
};
