import { Card, Col, Container, Row } from "react-bootstrap";
import { useContext, useState } from "react";
import * as Sentry from '@sentry/react';
import { AuthContext } from "../AuthContext";

export default function Login() {
  const authContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<"wrong_password" | null>(null);

  return (
    <div className="app">
      <Container>
        <Row>
          <Col sm={12} lg={6}>
            <Card>
              <p>Login page</p>
              <p>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </p>
              <p>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </p>
              {error === "wrong_password" && (
                <p>Password incorrect. Please try again.</p>
              )}
              <p>
                <button
                  onClick={async () => {
                    try {
                      setError(null);
                      await authContext.signInWithEmailAndPassword(
                        email,
                        password
                      );
                    } catch (e) {
                      const errorObj = e as object;
                      if ("code" in errorObj) {
                        if (errorObj.code === 'auth/wrong-password') {
                          setError("wrong_password");
                        } else {
                          Sentry.captureException(e);
                        }
                      } else {
                        Sentry.captureException(e);
                      }
                    }
                  }}
                >
                  Sign in
                </button>
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
