import { Concept } from "../schema";

export interface TimePeriodResult {
  date: Date;
  score: number;
}

export interface ConceptLearningState {
  concept: Concept;
  results: TimePeriodResult[];
}

export interface LearningResult {
  success: boolean;
}

export interface Confidence {
  confidence: number;
  due: Date;
}

function calculateScoreIncrement(
  success: boolean,
  previousResult?: TimePeriodResult
): number {
  if (previousResult === undefined) {
    return success ? 1 : -1;
  } else {
    const dateDifferenceMS =
      new Date().valueOf() - previousResult.date.valueOf();
    const msPerDay = 1000 * 60 * 60 * 24;

    // Round up, so that we always consider that there is some score increment
    // even if it's been less than a day.
    const dayDifference = Math.ceil(dateDifferenceMS / msPerDay);

    if (success) {
      return dayDifference;
    } else {
      const score = -Math.ceil(1 / (Math.sqrt(dayDifference) + 1));
      if (Number.isNaN(score)) {
        throw new Error("Generated NaN for score");
      }
      return score;
    }
  }
}

export function updateConceptLearningState(
  existingState: ConceptLearningState,
  result: LearningResult
): ConceptLearningState {
  const newState = { ...existingState };

  const previousResult =
    existingState.results[existingState.results.length - 1];

  newState.results = [
    ...existingState.results,
    {
      date: new Date(),
      score: calculateScoreIncrement(result.success, previousResult),
    },
  ];

  return newState;
}

function addDays(inputDate: Date, numDays: number): Date {
  return new Date(inputDate.valueOf() + numDays * 60 * 60 * 24 * 1000);
}

export function getConfidence(
  vocabularyState: ConceptLearningState
): Confidence {
  const clamp = (num: number, min: number, max: number) =>
    Math.min(Math.max(num, min), max);

  const aggregateScore = clamp(
    vocabularyState.results
      .map(
        (result, index) =>
          result.score / (vocabularyState.results.length - index)
      )
      .reduce((a, b) => a + b, 0),
    0,
    100
  );

  const confidence = Math.sqrt(aggregateScore / 100);

  if (vocabularyState.results.length === 0) {
    const due = new Date();
    due.setHours(0, 0, 0, 0);
    return {
      confidence: 0,
      due,
    };
  } else {
    const lastResult =
      vocabularyState.results[vocabularyState.results.length - 1];

    let dueDate: Date;

    if (lastResult.score > 0) {
      dueDate = addDays(lastResult.date, lastResult.score);
    } else {
      dueDate = addDays(lastResult.date, 1);
      dueDate.setHours(0, 0, 0, 0);
    }

    return {
      confidence,
      due: dueDate,
    };
  }
}
