import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Home from "./pages/Home";
import AdminRoute from "./components/AdminRoute";
import Login from "./pages/Login";
import { AdminHomePage } from "./pages/AdminHomePage";
import Illustrations from "./components/admin/Illustrations";
import AdminContainer from "./components/admin/AdminContainer";
import UploadImage from "./components/admin/UploadImage";
import Concepts from "./components/admin/Concepts";
import ConceptEdit from "./components/admin/ConceptEdit";
import { getConcept } from "./ConceptStore";
import ConceptAdd from "./components/admin/ConceptAdd";
import { getFirebase } from "./firebase";

const firebaseApp = getFirebase();
const firebaseDB = getFirestore(firebaseApp);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/admin",
    element: (
      <AdminRoute>
        <AdminContainer />
      </AdminRoute>
    ),
    children: [
      { index: true, element: <AdminHomePage /> },
      {
        path: "concepts",
        element: <Concepts />,
      },
      {
        path: "concepts/:conceptId",
        loader: async ({ params }) => {
          if (params.conceptId !== undefined) {
            const concept = await getConcept({ firebaseDB }, params.conceptId);
            return { concept };
          } else {
            throw new Error("No concept ID specified");
          }
        },
        element: <ConceptEdit />,
      },
      {
        path: "concepts/add",
        element: <ConceptAdd />,
      },
      {
        path: "illustrations",
        element: <Illustrations />,
      },
      {
        path: "illustrations/add",
        element: <UploadImage />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}
