import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { UserStateContext } from "../UserStateContext";

// Show the user a summary of their vocabulary. This is intended to be a simple
// thing that can hang on a page in a not-too-distracting way but give them
// a sense that they are making progress.
export default observer(function VocabularyStatusCard() {
  const userStateStore = useContext(UserStateContext);

  return (
    <div className="card vocabulary-status">
      <table>
        <tbody>
          <tr>
            <td>Words seen</td>
            <td>{userStateStore.vocabularySummary.wordsSeen}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
