import { createContext, useContext, useEffect, useMemo } from "react";
import * as Sentry from "@sentry/react";

export interface Callbacks {
  onComplete?: () => void;
  onPlayStart?: () => void;
}

export interface AudioSettings {
  onPermissionDenied: () => void;
}

// We use a context to allow customizing how we'll handle the case where audio
// permission is denied. Showing an alert is a reasonable default, but
// it might be better to navigate to a different page at the top level.
export const AudioContext = createContext<AudioSettings>({
  onPermissionDenied: () => {
    window.alert(
      "In order to work properly, you need to give this site permission to play audio."
    );
  },
});

// React hook to play audio. This takes care of stopping the audio if it
// is unmounted.
//
// In React development in Strict mode, we expect to be unmounted and
// remounted on every render, so this is useful in preventing bogus
// doubled audio.
export const useAudio = ({onComplete, onPlayStart}: Callbacks, path?: string) => {
  const audioContext = useContext(AudioContext);

  useEffect(() => {
    // We allow the URL to be undefined, because it avoids us needing
    // to call hooks conditionally.
    if (path === undefined) {
      return;
    }

    const url = `https://static.langsnap.com/${path}`;
    const audio = new Audio(url);

    const onError = () => {
      Sentry.captureEvent({ message: "Failed to load audio", extra: { url } });
    };
    audio.addEventListener("error", onError);

    const onCanPlayThrough = () => {
      audio.play().catch((e) => {
        if (e instanceof DOMException && e.name === "NotAllowedError") {
          audioContext.onPermissionDenied();
        }
      });

      if (onPlayStart !== undefined) {
        onPlayStart();
      }
    };
    audio.addEventListener("canplaythrough", onCanPlayThrough);

    const onEnded = () => {
      if (onComplete !== undefined) {
        onComplete();
      }
    };
    audio.addEventListener("ended", onEnded);

    return () => {
      audio.removeEventListener("error", onError);
      audio.removeEventListener("canplaythrough", onCanPlayThrough);
      audio.removeEventListener("ended", onEnded);
      audio.pause();
    };
  }, [path, audioContext, onComplete, onPlayStart]);
};

// Get an audio ready to be played, and return a trigger function
export const useDelayedAudio = (path: string): (() => Promise<void>) => {
  const audioContext = useContext(AudioContext);

  const audio = useMemo(() => {
    const url = `https://static.langsnap.com/${path}`;
    const audio = new Audio(url);
    audio.addEventListener("error", () => {
      Sentry.captureEvent({ message: "Failed to load audio", extra: { url } });
    });
    return audio;
  }, [path]);

  return async () => {
    audio.play().catch((e) => {
      if (e instanceof DOMException && e.name === "NotAllowedError") {
        audioContext.onPermissionDenied();
      }
    });

    const waitForFinish = new Promise((resolve) => {
      audio.addEventListener("ended", resolve);
    });

    await waitForFinish;
  };
};
