import { useContext, useEffect, useRef, useState } from "react";
import { Alert, Breadcrumb, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { S3Client } from "@aws-sdk/client-s3";
import { toast } from "react-toastify";

import { saveImage } from "../../AdminDataStore";
import { AdminStoreContext } from "../../AdminDataStoreContext";
import { ErrorCard } from "../ErrorCard";

// Admin interface to upload a new image to the database, creating the
// object in S3 and linking it to a record in Firebase.
export const UploadImage = () => {
  const [state, setState] = useState<"idle" | "saving" | "success" | "error">();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [s3Client, setS3Client] = useState<S3Client | undefined>(undefined);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const adminStoreContext = useContext(AdminStoreContext);

  useEffect(() => {
    if (
      process.env.REACT_APP_AWS_ACCESS_KEY_ID === undefined ||
      process.env.REACT_APP_AWS_SECRET_ACCESS_KEY === undefined
    ) {
      return;
    }

    const credentials = {
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    };

    const client = new S3Client({
      region: "eu-west-1",
      credentials,
    });

    setS3Client(client);
  }, []);

  if (adminStoreContext === undefined) {
    return <ErrorCard>Not logged in</ErrorCard>;
  }

  const SuccessToast = () => <div>File successfully uploaded</div>;

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          href="/admin"
          linkAs={Link}
          linkProps={{ to: "/admin" }}
        >
          Admin home
        </Breadcrumb.Item>

        <Breadcrumb.Item
          href="/admin/illustrations"
          linkAs={Link}
          linkProps={{ to: "/admin/illustrations" }}
        >
          Illustrations
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Add</Breadcrumb.Item>
      </Breadcrumb>

      {state === "error" && (
        <Alert variant="danger">An error occurred while uploading</Alert>
      )}

      <Form>
        <Form.Label>Image</Form.Label>

        {/* Can't use react-bootstrap Form.Control since it doesn't seem to assign a specific
            enough type to the argument to onChange */}
        <input
          ref={fileInputRef}
          className="form-control"
          type="file"
          onChange={(e) => {
            if (e.target.files !== null && e.target.files.length > 0) {
              setFile(e.target.files[0]);
            }
          }}
        />

        <button
          disabled={file === undefined || state === "saving"}
          type="button"
          onClick={() => {
            if (s3Client !== undefined && file !== undefined) {
              setState("saving");
              file
                .arrayBuffer()
                .then((arrayBuffer) => {
                  const buffer = Buffer.from(arrayBuffer);
                  return saveImage(
                    adminStoreContext,
                    s3Client,
                    file.name,
                    buffer
                  );
                })
                .then(() => {
                  toast(<SuccessToast />);
                  setFile(undefined);
                  if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                  }
                  setState("success");
                })
                .catch(() => {
                  setState("error");
                });
            }
          }}
        >
          Upload
        </button>
      </Form>
    </>
  );
};

export default UploadImage;
